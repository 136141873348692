<template>
  <div class="app">
    <div class="header-form">
      <div class="admin_main_block_left">
        <el-form>
          <el-form-item label="时间">
            <el-date-picker
              v-model="date"
              @change="orderDate" @input="immediUpdate"
              type="monthrange"
              range-separator="~"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              start-placeholder="开始月份"
              end-placeholder="结束月份"
            >
              <i class="el-icon-date"></i>
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" plain icon="el-icon-search" @click="listData()"
              >条件筛选</el-button
            >
            <el-button type="primary" plain icon="el-icon-reset" @click="reset()"
            >重置</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="admin_main_block_right">
        <div>
          <el-button type="primary" @click="exportData" icon="el-icon-tickets"
            >导出数据</el-button
          >
        </div>
      </div>
    </div>
    <el-table :data="list" @selection-change="handleSelectionChange">
      <el-table-column type="selection"></el-table-column>
      <el-table-column
        prop="externalId"
        label="提现流水号"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="cashAmount"
        label="提现金额"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="cashFee"
        label="提现手续费"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="cashTaxFee"
        label="提现税费"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
          prop="realAmount"
          label="到账金额"
          :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="creditStatusText"
        label="出账状态"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="creditTime"
        label="出账时间"
        :show-overflow-tooltip="true"
      ></el-table-column>
    </el-table>
    <div class="admin_table_main_pagination">
      <el-pagination
        @current-change="currentChange"
        @size-change="handleSizeChange"
        background
        :total="totalData"
        :page-size="pageSize"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        layout="sizes, prev, pager, next, total, jumper"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    shopId: String,
  },
  data() {
    return {
      list: [],
      date: "",
      loading: false,
      totalData: 0, // 总条数
      pageSize: 20,
      currentPage: 0,
      selectId: "",
      where: {
        pageNum: 0,
        pageSize: 0,
        shopId: 0,
      },
    };
  },
  methods: {
    listData() {
      this.loading = true;
      this.where.pageNum = this.currentPage;
      this.where.pageSize = this.pageSize;
      this.where.accountOwnerId = this.shopId;
      this.where.accountOwnerRole = 'SHOP';
      this.where.accountBizType = 'WITHDRAW';
      this.$post(this.$api.profitCashOrderList, this.where).then((res) => {
        this.totalData = res.data.total;
        this.where.pageNum = res.data.pages;
        this.list = res.data.records;
        this.loading = false;
      });
    },
    reset(){
      this.date = '';
      this.where = {};
      this.listData();
    },
    handleSelectionChange(e) {
      let ids = [];
      e.forEach((v) => {
        ids.push(v.id);
      });
      this.selectId = ids.join(",");
    },
    currentChange(e) {
      this.currentPage = e;
      this.listData();
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.listData();
    },
    //粉丝详情
    fansDetail() {
      // console.log("fansDetail");
    },
    orderDate(obj) {
      if (obj) {
        this.where.startDt = obj[0];
        this.where.endDt = obj[1];
      }
    },
    immediUpdate(e) {
      this.$nextTick(() => {
        console.log(e)
        if(e==null){
          this.where.startDt = '';
          this.where.endDt = '';
        }
      });
    },
    exportData() {
      // console.log("exportData");
    },
  },
  created() {
    this.listData();
  },
};
</script>
