<template>
  <div class="app">
    <div class="header-form">
      <div class="admin_main_block_left">
        <el-form>
          <el-form-item label="时间">
            <el-date-picker
              v-model="date"
              @change="orderDate" @input="immediUpdate"
              type="monthrange"
              range-separator="~"
              value-format="yyyy-MM"
              format="yyyy-MM"
              start-placeholder="开始月份"
              end-placeholder="结束月份"
            >
              <i class="el-icon-date"></i>
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" plain icon="el-icon-search" @click="listData()"
              >条件筛选</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
    <el-table :data="list" @selection-change="handleSelectionChange">
      <el-table-column type="selection"></el-table-column>
      <el-table-column
        prop="orderNo"
        label="订单编号"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="goodsId"
        label="商品ID"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="goodsId"
        label="商品图片"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="shopId"
        label="商品名称"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="purchaseQuantity"
        label="数量"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="createDate"
        label="下单时间"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="shopId"
        label="会员账号"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="purchaseQuantity"
        label="消耗惠豆"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="freight"
        label="运费（元）"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="payDate"
        label="支付时间"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="shopId"
        label="订单来源"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="orderStatus"
        label="订单状态"
        :show-overflow-tooltip="true"
      ></el-table-column>
    </el-table>
    <div class="admin_table_main_pagination">
      <el-pagination
        @current-change="currentChange"
        @size-change="handleSizeChange"
        background
        :total="totalData"
        :page-size="pageSize"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        layout="sizes, prev, pager, next, total, jumper"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    shopId: String,
  },
  data() {
    return {
      list: [],
      date: "",
      totalData: 0, // 总条数
      pageSize: 20,
      currentPage: 0,
      selectId: "",
      where: {
        pageNum: 0,
        pageSize: 0,
        shopId: 0,
        endDate: "",
        fansUserId: 0,
        lockType: 0,
        roleId: 0,
        startDate: "",
        userId: 0,
      },
    };
  },
  methods: {
    listData() {
      this.where.pageNum = this.currentPage;
      this.where.pageSize = this.pageSize;
      this.where.shopId = this.shopId;
      this.$post(this.$api.exchangeCommodityOrderRecord, this.where).then(
        (res) => {
          this.totalData = res.data.total;
          this.where.pageNum = res.data.pages;
          this.list = res.data.records;
        }
      );
    },
    handleSelectionChange(e) {
      let ids = [];
      e.forEach((v) => {
        ids.push(v.id);
      });
      this.selectId = ids.join(",");
    },
    currentChange(e) {
      this.currentPage = e;
      this.listData();
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.listData();
    },
    orderDate(obj) {
      if (obj) {
        this.where.startDate = obj[0];
        this.where.endDate = obj[1];
      }
    },
    immediUpdate(e) {
      this.$nextTick(() => {
        console.log(e)
        if(e==null){
          this.where.startDate = '';
          this.where.endDate = '';
        }
      });
    },
  },
  created() {
    this.listData();
  },
};
</script>
