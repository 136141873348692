<template>
  <div class="app">
    <div class="header-form">
      <div class="admin_main_block_left">
        <el-form>
          <el-form-item label="类型">
            <el-select v-model="where.reasonsType" placeholder="类型">
              <el-option label="全部" value=""></el-option>
              <el-option label="消费返悦豆" value="1"></el-option>
              <el-option label="兑换商品" value="2"></el-option>
              <el-option label="平台消息补贴" value="3"></el-option>
              <el-option label="购买VIP权益" value="4"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="时间">
            <el-date-picker
              v-model="date"
              @change="orderDate" @input="immediUpdate"
              type="monthrange"
              range-separator="~"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              start-placeholder="开始月份"
              end-placeholder="结束月份"
            >
              <i class="el-icon-date"></i>
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" plain icon="el-icon-search" @click="listData()"
            >条件筛选</el-button
            >
            <el-button type="primary" plain icon="el-icon-reset" @click="reset()"
            >重置</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="admin_main_block_right">
        <div>
          <el-button type="primary" @click="exportData" icon="el-icon-tickets"
            >导出数据</el-button
          >
        </div>
      </div>
    </div>
    <el-table :data="list" @selection-change="handleSelectionChange">
      <el-table-column type="selection"></el-table-column>
      <el-table-column
        prop="orderNo"
        label="交易/订单编号"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="reasonsType"
        label="类型"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.reasonsType == 1"
            >消费返悦豆</el-tag
          >
          <el-tag type="warning" v-else-if="scope.row.reasonsType == 2"
            >兑换商品</el-tag
          >
          <el-tag type="danger" v-else-if="scope.row.reasonsType == 3"
            >平台消息补贴</el-tag
          >
          <el-tag type="danger" v-else-if="scope.row.reasonsType == 4"
            >购买VIP权益</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="reasonsTypeText"
        label="名称"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="expenditureAmount"
        label="消费金额"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="quantity"
        label="惠豆数量"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
          prop="operationType"
          label="增加/扣减"
          :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.operationType == 1">增加</el-tag>
          <el-tag type="danger"  v-if="scope.row.operationType == 2">扣减</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="createDate"
        label="时间"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="remark"
        label="说明"
        :show-overflow-tooltip="true"
      ></el-table-column>
    </el-table>
    <div class="admin_table_main_pagination">
      <el-pagination
        @current-change="currentChange"
        @size-change="handleSizeChange"
        background
        :total="totalData"
        :page-size="pageSize"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        layout="sizes, prev, pager, next, total, jumper"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    shopId: String,
  },
  data() {
    return {
      list: [],
      date: "",
      totalData: 0, // 总条数
      pageSize: 20,
      currentPage: 0,
      selectId: "",
      where: {
        pageNum: 0,
        pageSize: 0,
        shopId: 0,
      },
    };
  },
  methods: {
    listData() {
      this.where.pageNum = this.currentPage;
      this.where.pageSize = this.pageSize;
      this.where.shopId = this.shopId;
      this.$post(this.$api.fenRunRecord, this.where).then((res) => {
        this.totalData = res.data.total;
        this.where.pageNum = res.data.pages;
        this.list = res.data.records;
      });
    },
    reset(){
      this.date = '';
      this.where = {};
      this.listData();
    },
    handleSelectionChange(e) {
      let ids = [];
      e.forEach((v) => {
        ids.push(v.id);
      });
      this.selectId = ids.join(",");
    },
    currentChange(e) {
      this.currentPage = e;
      this.listData();
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.listData();
    },
    //粉丝详情
    fansDetail() {
      // console.log("fansDetail");
    },
    orderDate(obj) {
      if (obj) {
        this.where.createDateStart = obj[0];
        this.where.createDateEnd = obj[1];
      }
    },
    immediUpdate(e) {
      this.$nextTick(() => {
        console.log(e)
        if(e==null){
          this.where.createDateStart = '';
          this.where.createDateEnd = '';
        }
      });
    },
    exportData() {
      // console.log("exportData");
    },
  },
  created() {
    this.listData();
  },
};
</script>
