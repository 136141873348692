<template>
  <div class="qingwu">
    <div class="admin_main_block">
      <div class="admin_breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/Admin/index' }"
          >首页
          </el-breadcrumb-item
          >
          <el-breadcrumb-item>商家联盟</el-breadcrumb-item>
          <el-breadcrumb-item>门店列表</el-breadcrumb-item>
          <el-breadcrumb-item>门店详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="admin_main_block admin_m15">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="基本信息" name="first">
          <el-row :gutter="40">
            <el-col :span="6">
              <div class="sub-title">商家ID</div>
              <el-input
                  placeholder=""
                  v-model="infoDetail.merchantId"
                  :disabled="true"
              ></el-input>
            </el-col>
            <el-col :span="6">
              <div class="sub-title">商家名称</div>
              <el-input
                  placeholder=""
                  v-model="query.merchantName"
                  :disabled="true"
              ></el-input>
            </el-col>
            <el-col :span="6">
              <div class="sub-title">商家手机号（登录账号）</div>
              <el-input
                  placeholder=""
                  v-model="infoDetail.merchantMobileNo"
                  :disabled="true"
              ></el-input>
            </el-col>
            <el-col :span="6"></el-col>
          </el-row>
          <el-row :gutter="40">
            <el-col :span="6">
              <div class="sub-title">门店ID</div>
              <el-input
                  placeholder=""
                  v-model="query.shopId"
                  :disabled="true"
              ></el-input>
            </el-col>
            <el-col :span="6">
              <div class="sub-title">门店名称</div>
              <el-input
                  placeholder=""
                  v-model="query.shopName"
                  :disabled="true"
              ></el-input>
            </el-col>
            <el-col :span="6">
              <div class="sub-title">经营类型</div>
              <el-input
                  placeholder=""
                  v-model="query.categoryName"
                  :disabled="true"
              ></el-input>
            </el-col>
            <el-col :span="6">
              <div class="sub-title">所属区域</div>
              <el-input
                  placeholder=""
                  v-model="query.shopAreaCode"
                  :disabled="true"
              ></el-input>
            </el-col>
          </el-row>
          <!--          <el-row :gutter="40">-->
          <!--            <el-col :span="24">-->
          <!--              <span class="grid-divider">来源/推荐</span>-->
          <!--              <el-divider></el-divider>-->
          <!--            </el-col>-->
          <!--            <el-col :span="6">-->
          <!--              <div class="sub-title">开发者账号</div>-->
          <!--              <el-input-->
          <!--                placeholder=""-->
          <!--                v-model="infoDetail.openShopUserAccount"-->
          <!--                :disabled="true"-->
          <!--              ></el-input>-->
          <!--            </el-col>-->
          <!--            <el-col :span="6">-->
          <!--              <div class="sub-title">开发者名称</div>-->
          <!--              <el-input-->
          <!--                placeholder=""-->
          <!--                v-model="infoDetail.openShopUserRealName"-->
          <!--                :disabled="true"-->
          <!--              ></el-input>-->
          <!--            </el-col>-->
          <!--            <el-col :span="6">-->
          <!--              <div class="sub-title">开发者ID</div>-->
          <!--              <el-input-->
          <!--                placeholder=""-->
          <!--                v-model="infoDetail.openShopUserId"-->
          <!--                :disabled="true"-->
          <!--              ></el-input>-->
          <!--            </el-col>-->
          <!--            <el-col :span="6"> </el-col>-->
          <!--          </el-row>-->
          <el-row :gutter="40">
            <el-col :span="24">
              <span class="grid-divider">证件信息</span>
              <el-divider></el-divider>
            </el-col>
            <el-col :span="6">
              <div class="sub-title">经营许可证</div>
              <el-image
                  class="el-imag"
                  :src="
                  infoDetail.businessCertificateList
                    ? infoDetail.businessCertificateList[0]
                    : ''
                "
                  :preview-src-list="infoDetail.businessCertificateList"
                  fit="contain"
              >
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </el-col>
            <el-col :span="6">
              <div class="sub-title">门头照片</div>
              <el-image
                  class="el-imag"
                  :src="infoDetail.shopHeadImage ? infoDetail.shopHeadImage : ''"
                  fit="contain"
              >
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </el-col>
            <el-col :span="6">
              <div class="sub-title">店内环境照</div>
              <el-image
                  class="el-imag"
                  :src="
                  infoDetail.shopImageList ? infoDetail.shopImageList[0] : ''
                "
                  :preview-src-list="infoDetail.shopImageList"
                  fit="contain"
              >
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </el-col>
            <el-col :span="6"></el-col>
          </el-row>
          <el-row :gutter="40">
            <el-col :span="24">
              <span class="grid-divider">提现账号</span>
              <el-divider></el-divider>
            </el-col>
            <el-col>
              <el-row>
                <el-col
                    :span="6"
                    v-for="(row, i) in infoDetail.merchantBankList"
                    :key="i"
                    style="padding: 20px 5px; border: 1px dashed #d9d9d9"
                >
                  <el-col :span="6" style="padding: 10px; text-align: center">
                    <el-avatar :size="50" src=""></el-avatar>
                  </el-col>
                  <el-col :span="18" style="font-size: 14px">
                    <h3 class="layadmin-title">
                      <strong>{{ row.bankName }}</strong>
                    </h3>
                    <p class="layadmin-textimg">卡号：{{ row.bankNo }}</p>
                    <div class="layadmin-address">储蓄卡</div>
                  </el-col>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-row :gutter="40">
            <el-col :span="24">
              <span class="grid-divider">操作纪录</span>
              <el-divider></el-divider>
            </el-col>
            <el-col :span="24">
              <el-timeline>
                <el-timeline-item
                    v-for="(activity, index) in activities"
                    :key="index"
                    :timestamp="activity.timestamp"
                >
                  {{ activity.content }}
                </el-timeline-item>
              </el-timeline>
            </el-col>
          </el-row>
          <el-divider></el-divider>
          <div style="text-align: right">
            <el-button icon="el-icon-back" type="primary" @click="backShop">
              驳回门店信息
            </el-button
            >
          </div>
        </el-tab-pane>
        <el-tab-pane label="收款明细" name="collection">
          <collection :shopId="query.shopId" v-if="collectionIs"></collection>
        </el-tab-pane>
        <el-tab-pane label="收益明细" name="earnings">
          <earnings :shopId="query.shopId" v-if="earningsIs"></earnings>
        </el-tab-pane>
        <el-tab-pane label="惠豆明细" name="huidou">
          <huidou :shopId="query.shopId" v-if="huidouIs"></huidou>
        </el-tab-pane>
        <el-tab-pane label="粉丝明细" name="fans">
          <fans :shopId="query.shopId" v-if="fansIs"></fans>
        </el-tab-pane>
        <el-tab-pane label="门店订单" name="shopOrder">
          <shopOrder :shopId="query.shopId" v-if="shopOrderIs"></shopOrder>
        </el-tab-pane>
        <el-tab-pane label="设备及台卡" name="device">
          <device :shopId="query.shopId" v-if="deviceIs"></device>
        </el-tab-pane>
        <el-tab-pane label="兑换订单" name="order">
          <order :shopId="query.shopId" v-if="orderIs"></order>
        </el-tab-pane>
        <el-tab-pane label="提现明细" name="withdraw">
          <withdraw :shopId="query.shopId" v-if="withdrawIs"></withdraw>
        </el-tab-pane>
        <el-tab-pane label="会员卡" name="cardList">
          <cardList :shopId="query.shopId" v-if="cardListIs"></cardList>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import collection from "@/components/admin/merchant/shop/collection.vue";
import earnings from "@/components/admin/merchant/shop/earnings.vue";
import fans from "@/components/admin/merchant/shop/fans.vue";
import order from "@/components/admin/merchant/shop/order.vue";
import huidou from "@/components/admin/merchant/shop/huidou.vue";
import withdraw from "@/components/admin/merchant/shop/withdraw.vue";
import cardList from "@/components/admin/merchant/shop/cardList";
import shopOrder from "@/components/admin/merchant/shop/shopOrder";
import device from "@/components/admin/merchant/shop/device";


export default {
  components: {
    collection,
    earnings,
    fans,
    order,
    huidou,
    withdraw,
    cardList,
    shopOrder,
    device,
  },
  data() {
    return {
      infoDetail: {},
      shopId: 0,
      query: {},
      activeName: "first",
      /*定义前一个活动Tab,初始化默认为 ActiveTab */
      oldTab: "add",
      /*定义Tab是否加载-第一个默认加载 */
      collectionIs: false,
      earningsIs: false,
      fansIs: false,
      orderIs: false,
      huidouIs: false,
      withdrawIs: false,
      cardListIs: false,
      shopOrderIs: false,
      deviceIs: false,
      activities: [
        {
          content: "活动按期开始",
          timestamp: "2018-04-15",
        },
        {
          content: "通过审核",
          timestamp: "2018-04-13",
        },
        {
          content: "创建成功",
          timestamp: "2018-04-11",
        },
      ],
    };
  },
  methods: {
    handleClick(tab) {
      this[tab.name + "Is"] = true;
      if (tab.name != this.oldTab) {
        this[this.oldTab + "Is"] = false;
        this.oldTab = tab.name;
      }
    },
    infoData() {
      this.$get(
          this.$api.systemShopDetail + "/" + this.$route.query.shopId,
          this.whale
      ).then((res) => {
        this.infoDetail = res.data;
      });
    },
    //驳回商户信息
    backShop() {
      // console.log("backShop");
    },
  },
  created() {
    this.query = this.$route.query;
    this.infoData();
  },
};
</script>
<style lang="scss" scoped>
.el-row {
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
}

.el-col {
  border-radius: 4px;
}

.el-tab-pane {
  padding-top: 10px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.el-divider--horizontal {
  margin: 12px 0px 12px 0px;
}

.sub-title {
  min-height: 26px;
}

.el-imag {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 260px;
  height: 178px;
}

.el-imag:hover {
  border-color: #409eff;
}

.el-icon-picture-outline {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.layadmin-contact-box {
  width: 300px;
  display: inline-block;
  border: 1px solid #e7eaec;
  padding: 40px 0 40px 0;
}

.layadmin-text-center {
  text-align: center;
}

.layadmin-text-center .el-image {
  max-width: 40px;
  border-radius: 50%;
  margin-top: 5px;
  border: 1px dashed #d9d9d9;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 40px;
  height: 40px;
}

.el-card {
  text-align: center;
}

.header-form {
  height: 40px;
  padding: 10px 0 10px 0;
}
</style>
