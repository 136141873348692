<template>
  <div class="qingwu">
    <div class="admin_main_block admin_m15">
      <div class="admin_table_main">
        <div class="header-form">
          <div class="admin_main_block_left">
            <el-form>
              <el-form-item label="订单号">
                <el-input
                    v-model="where.orderNo"
                >
                </el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" plain icon="el-icon-search" @click="getList()"
                >条件筛选
                </el-button
                >
                <el-button type="primary" plain icon="el-icon-reset" @click="reset()"
                >重置
                </el-button
                >
              </el-form-item>
            </el-form>
          </div>
        </div>
        <el-table :data="list"
                  @selection-change="handleSelectionChange"
                  v-loading="loading"
                  element-loading-text="拼命加载中"
                  element-loading-spinner="el-icon-loading"
                  element-loading-background="rgba(0, 0, 0, 0.1)"
        >
          <el-table-column type="selection"></el-table-column>
<!--          <el-table-column prop="merchantName" label="商家名"></el-table-column>-->
          <el-table-column prop="deviceName" label="设备名称"></el-table-column>
          <el-table-column prop="deviceStateName" label="设备状态"></el-table-column>
          <el-table-column prop="cashPledge" label="押金"></el-table-column>
          <el-table-column prop="deviceType" label="设备类型"></el-table-column>
          <el-table-column prop="snCode" label="SN编码"></el-table-column>
          <el-table-column prop="payCode" label="付款码">
<!--            <template slot-scope="scope">-->
<!--              <el-tag type="success" v-if="scope.row.payType == 1">-->
<!--                微信-->
<!--              </el-tag>-->
<!--              <el-tag type="success" v-else-if="scope.row.payType == 2">-->
<!--                支付宝-->
<!--              </el-tag>-->
<!--              <el-tag type="success" v-else-if="scope.row.payType == 3">-->
<!--                平台支付-->
<!--              </el-tag>-->
<!--              <el-tag type="success" v-else-if="scope.row.payType == 4">-->
<!--                银联-->
<!--              </el-tag>-->
<!--            </template>-->
          </el-table-column>
          <el-table-column prop="phone" label="业务手机号"></el-table-column>
          <el-table-column prop="stateName" label="状态">
<!--            <template slot-scope="scope">-->
<!--              <el-tag type="success" v-if="scope.row.state == 1">-->
<!--                已付款-->
<!--              </el-tag>-->
<!--              <el-tag type="danger" v-else-if="scope.row.state == 0">-->
<!--                待付款-->
<!--              </el-tag>-->
<!--            </template>-->
          </el-table-column>
          <el-table-column prop="createDate" label="创建日期"></el-table-column>
        </el-table>
        <div class="admin_table_main_pagination">
          <el-pagination
              @current-change="currentChange"
              @size-change="handleSizeChange"
              background
              :total="totalData"
              :page-size="pageSize"
              :current-page="currentPage"
              :page-sizes="[10, 20, 50, 100]"
              layout="sizes, prev, pager, next, total, jumper"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    shopId: String,
  },
  data() {
    return {
      list: [],
      loading: false,
      totalData: 0, // 总条数
      pageSize: 20,
      currentPage: 0,
      where: {},
    }
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      this.where.pageNum = this.currentPage;
      this.where.pageSize = this.pageSize;
      this.where.shopId = this.shopId;
      this.$post(this.$api.systemShopDevice, this.where).then((res) => {
        this.totalData = res.data.count;
        this.list = res.data.data;
        this.loading = false;
      });
    },
    reset() {
      this.where = {};
      this.getList();
    },
    currentChange(e) {
      this.currentPage = e;
      this.getList();
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.getList();
    },
    handleSelectionChange() {
    },
  }
}
</script>